import CustomLink from '../components/links/CustomLink'
import styles from './Page.module.scss'

export default function Policy() {
  return (
    <div className={styles.root}>
      <CustomLink to={'/'} className="back">
        На Главную
      </CustomLink>

      <div className={styles.title}>Политика конфиденциальности</div>

      <div className={styles.privacy}>
        <p>
          Политика конфиденциальности (далее — Политика) регулирует порядок
          обработки и использования информации, которую ООО «Momentum business
          star», (далее – Организация) может получить с устройства во время
          использования Приложения Пользователем. <br /> <br /> Использование
          Приложения означает согласие Пользователя с Политикой и указанными в
          ней условиями обработки и использования информации, получаемой с
          Устройства.
          <br /> <br /> Если Пользователь не согласен с условиями настоящей В
          случае несогласия с условиями Политики, Пользователь должен прекратить
          использование Приложения.
        </p>
        <h3>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ </h3>
        <div>
          <p>
            1.1. Устройство – мобильное техническое устройство, на котором
            установлено Приложение.
          </p>
          <p>1.2. Пользователь — физическое лицо, использующее Приложение. </p>
          <p>
            Персональные данные —информация, прямо или косвенно относящаяся к
            Пользователю, которую он предоставляет в процессе регистрации или
            использования Приложения.
          </p>
          <p>
            1.3. Законодательство — действующее законодательство Российской
            Федерации.
          </p>
          <p>
            1.4. Приложение – мобильное приложение для использования услуг. Все
            исключительные права на Приложение и его отдельные элементы (включая
            программное обеспечение, дизайн) принадлежат Организации в полном
            объеме.
          </p>
        </div>

        <h3>2. СБОР И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
        <div>
          <p>
            Состав информации, которая может быть получена с устройства
            пользователя при использовании Приложения и цели её получения:{' '}
          </p>
          <p>
            2.1. Информация об адресе точки подключения пользователя и IP-адресе
            для повышения безопасности совершения операций при использовании
            Приложения.
          </p>
          <p>
            2.2. Информация о модели устройства, версии операционной системы для
            анализа возможных ошибок в работе и для улучшения работы Приложения.
            Организация может передавать информацию о модели устройства и
            операционной системе сторонним организациям в обезличенном виде с
            целью анализа.
          </p>

          <p>
            2.3. Информация о местоположении устройства пользователя (на основе
            данных сети оператора сотовой связи, сигналов GPS и иных данных,
            получаемых посредствам сети Интернет) для информирования клиента при
            использовании Приложения.
          </p>

          <p>
            2.4. Фотоизображения, полученные с использованием камеры устройства
            или загруженные Пользователем из памяти устройства для получения и
            использования фотоизображений в рамках услуг, реализуемых в
            Приложении.
          </p>
        </div>

        <h3>3. УСЛОВИЯ ОБРАБОТКИ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ </h3>
        <div>
          <p>
            3.1. В соответствии с Политикой Организация осуществляет обработку
            только той информации и только для тех целей, которые определены в
            пункте 2.
          </p>
          <p>
            3.2. В приложение встроен скрипт, который обрабатывает собранную
            обезличенную информацию, исключая данные, относящие к определенному
            физическому лицу, и никак не связывает обрабатываемую информацию с
            ФИО пользователя.
          </p>
          <p>
            3.3. Организация может привлекать к обработке информации
            Пользователя партнёров, с которыми заключены соответствующие
            соглашения о конфиденциальности, для изложенных в настоящей Политике
            целей. Передача партнёрам обезличенных данных об использовании
            Приложения для целей улучшения работы Приложения осуществляется на
            основании договоров с партнерами.
          </p>
          <p>
            3.4. Информация Пользователя, касающаяся Приложения, может
            сохраняться на ресурсах Организации и партнёров в течение срока
            действия договорных отношений между Организацией и пользователем, а
            также в течение 5 лет после расторжения таких договоров.
          </p>

          <p>
            3.5. Информация Пользователя может быть предоставлена
            государственным органам в соответствии с требованиями действующего
            законодательства.
          </p>
        </div>

        <h3>4. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ </h3>
        <div>
          <p>
            4.1. Организация осуществляет надлежащую защиту Персональных и иных
            данных в соответствии с действующим законодательством.
          </p>
          <p>
            4.2. Организация принимает все зависящие от неё меры для защиты
            информации пользователя от неправомерного или случайного доступа
            третьих лиц, уничтожения, использования, изменения, копирования и
            распространения.
          </p>
        </div>

        <h3>5. ИЗМЕНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ </h3>
        <div>
          <p>
            5.1. Пользователь подтверждает, что все Персональные данные являются
            актуальными.
          </p>
          <p>
            5.2. Пользователь может в любой момент изменить (обновить,
            дополнить) Персональные данные посредством направления письменного
            заявления Организации.
          </p>
        </div>

        <h3>6. ИНЫЕ ПОЛОЖЕНИЯ</h3>
        <div>
          <p>
            6.1 Политика применима только к Приложению. Организация не
            контролирует и не несёт ответственность за информацию и последствия
            её передачи, отправленную пользователем третьей стороне, если такая
            передача произошла на ресурсе третьей стороны, куда пользователь мог
            перейти по ссылкам из Приложения.
          </p>
          <p>
            6.2 Организации имеют право вносить изменения в новых редакциях
            Политики и размещать их на сайте Организаций и/или в Сервисе.
            Обязанность самостоятельного ознакомления с актуальной редакцией
            Политики лежит на пользователе.
          </p>
        </div>
      </div>
    </div>
  )
}
